export default {
    "Login": "Connexion",
    "Logout": "Déconnexion",
    "Account": "Compte",
    "Home": "Accueil",
    "Invoices": "Factures",
    "Status": "Statut",
    "TotalEnergies Invoicing Admin Platform": "TotalEnergies Admin Plateforme de Facturation",

    // System Status
    "System Status": "État du système",
    "Everything is up and running": "Tout est opérationnel",
    "Auth Server": "Auth Serveur",
    "Frontend": "Frontend",
    "Backend": "Backend",
    "Admin Frontend": "Admin Frontend",
    "Admin Backend": "Admin Backend",
    "online": "en ligne",

    // User List
    "E-mail verified": "Courriel verifié",
    "Created": "Créé",
    "Enabled": "Activé",

    // Customers
    "User list": "Liste d'utilisateur",
    "Billing addresses": "Adresses de facturation",
    "Address details": "Détails de l'adresse",
    "Address updated successfully.": "Adresse mise à jour avec succès.",
    "Address list": "Liste des adresses",
    "Civility / Type": "Civilité / Type",
    "Name": "Nom",
    "Address": "Adresse",
    "No addresses found": "Aucune adresse trouvée",
    "E-mail": "Courriel",
    "VAT number is required": "Le numéro de TVA est requis",
    "Type is required": "Le type est requis",
    "Name is required": "Le nom est requis",
    "Civility is required": "La civilité est requise",
    "First name is required": "Le prénom est requis",
    "Last name is required": "Le nom est requis",
    "Address is required": "L'adresse est requise",
    "Zip/Postal code is required": "Le code postal est requis",
    "City is required": "La ville est requise",
    "Country is required": "Le pays est requis",
    "E-mail is required": "Le courriel est requis",
    "E-mail is invalid": "Le courriel est invalide",
    "Company": "Compagnie",
    "Private": "Particulier",
    "Civility": "Civilité",
    "Type": "Type",
    "Please select": "Veuillez sélectionner",
    "customer_title_0": "Autre",
    "customer_title_1": "M.",
    "customer_title_2": "Mme",
    "customer_title_3": "Mlle",
    "customer_title_4": "SA",
    "customer_title_5": "SARL",
    "customer_title_6": "ASBL",
    "customer_title_7": "SPRL",
    "customer_title_8": "SCRL",
    "customer_title_9": "SE",
    "customer_title_10": ".",
    "customer_title_11": "M.Mme.",
    "customer_title_12": "Indép.",
    "customer_title_13": "EURL",
    "customer_title_14": "SAS",
    "customer_title_15": "SNC",
    "customer_title_16": "SCS",
    "customer_title_17": "SCA",
    "First name": "Prénom",
    "Last name": "Nom de famille",
    "Company type": "Type de compagnie",
    "Company name": "Nom de la compagnie",
    "VAT": "TVA",
    "VAT number": "Numéro de TVA",
    "Starts with an country code (usually 2 letters) followed by up to 13 characters, e.g. BE09999999XX, FRXX999999999, NL999999999B01": "Commence par un code de pays (généralement 2 lettres) suivi d'un maximum de 13 caractères, par ex. BE09999999XX, FRXX999999999, NL999999999B01",
    "Street address": "Rue",
    "Zip/Postal code": "Code postal",
    "City": "Ville",
    "Country": "Pays",
    "Free text": "Texte libre",

    // Tasks
    "Tasks": "Tâches",
    "Task List": "Liste de tâches",
    "No pending tasks found": "Aucune tâche en attente trouvée",
    "All invoice requests have been processed": "Toutes les demandes de factures ont été traitées",

    // Dealers
    "Dealers": "Marchands",
    "Create Dealer Account": "Créer un compte marchand",
    "Dealer created successfully.": "Marchand a bien été créé.",
    "User ID": "Identifiant d'utilisateur",
    "verified": "vérifié",
    "not verified": "non vérifié",
    "E-mail is verified": "E-mail est vérifié",
    "Password": "Mot de passe",
    "Dealer ID": "Identifiant du marchand",
    "Use 0 for a TotalEnergies dealer": "Utilisez 0 pour un marchand de TotalEnergies",
    "Create": "Créer",

    // E-Mail
    "Send e-mail to customer": "Envoyer un e-mail au client",
    "E-Mail sent successfully.": "",
    "Customer": "Client",
    "E-mail type": "Type de courriel",
    "Invoice request finished": "Demande de facture terminée",
    "Invoice request": "Demande de facturation",
    "Invoice request ID": "ID de demande de facture",
    "Language": "Langue",
    "Send": "Envoyer",

    // Statistics
    "Statistics": "Statistiques",
    "Statistics Overview": "Aperçu des statistiques",
    "Loading": "Chargement",
    "Requests": "Demandes",
    "Monthly Invoices": "Factures mensuelles",
    "Success": "Succès",
    "Failure": "Échec",
    "Invoice Statistics": "Statistiques de facturation",
    "Daily Invoices": "Factures quotidiennes",
    "1 Month": "1 mois",
    "6 Months": "6 mois",
    "Current month": "Mois en cours",
    "1 Year": "1 année",
    "Current year": "Année actuelle",
    "All": "Tout",

    // Footer
    "Terms of service": "Conditions générales d’utilisation",
    "Personal data & cookies": "Données personnelles & cookies",
    "Sitemap": "Plan du site",
    "General conditions of sale": "Conditions générales de vente",
    "All our sites": "Tous nos sites",
    "Follow us": "Suivez-nous",
    "Follow us on Facebook (new window)": "Suivez-nous sur Facebook (nouvelle fenêtre)",
    "Follow us on Twitter (new window)": "Suivez-nous sur Twitter (nouvelle fenêtre)",
    "Follow us on LinkedIn (new window)": "Suivez-nous sur LinkedIn (nouvelle fenêtre)",
    "Follow us on YouTube (new window)": "Suivez-nous sur YouTube (nouvelle fenêtre)",
    "Follow us on Instagram (new window)": "Suivez-nous sur Instagram (nouvelle fenêtre)",
}
