export default {
    "Login": "Log in",
    "Logout": "Uitloggen",
    "Account": "Account",
    "Home": "Huis",
    "Invoices": "Facturen",
    "Status": "Status",
    "TotalEnergies Invoicing Admin Platform": "TotalEnergies Admin facturatieplatform",

    // System Status
    "System Status": "Systeem status",
    "Everything is up and running": "Alles draait",
    "Auth Server": "Auth Server",
    "Frontend": "Frontend",
    "Backend": "Backend",
    "Admin Frontend": "Admin Frontend",
    "Admin Backend": "Admin Backend",
    "online": "online",

    // User List
    "E-mail verified": "E-mail geverifieerd",
    "Created": "Gemaakt",
    "Enabled": "Ingeschakeld",

    // Customers
    "User list": "Gebruikers lijst",
    "Billing addresses": "Factuuradressen",
    "Address details": "Adresgegevens",
    "Address updated successfully.": "Adres succesvol bijgewerkt.",
    "Address list": "Adreslijst",
    "Civility / Type": "Beleefdheidsvorm / Type",
    "Name": "Naam",
    "Address": "Adres",
    "No addresses found": "Geen adressen gevonden",
    "E-mail": "E-mail",
    "VAT number is required": "BTW-nummer is verplicht",
    "Type is required": "Type is verplicht",
    "Name is required": "Naam is verplicht",
    "Civility is required": "Aanspreekvorm is verplicht",
    "First name is required": "Voornaam is verplicht",
    "Last name is required": "Achternaam is verplicht",
    "Address is required": "Adres is verplicht",
    "Zip/Postal code is required": "Postcode is verplicht",
    "City is required": "Plaats is verplicht",
    "Country is required": "Land is verplicht",
    "E-mail is required": "E-mail is verplicht",
    "E-mail is invalid": "E-mail is ongeldig",
    "Company": "Bedrijf",
    "Private": "Particulier",
    "Civility": "Aanspreekvorm",
    "Type": "Type",
    "Please select": "Selecteer alstublieft",
    "customer_title_0": "Ander",
    "customer_title_1": "Dhr.",
    "customer_title_2": "Mevr.",
    "customer_title_3": "Mej.",
    "customer_title_4": "NV",
    "customer_title_5": "BV",
    "customer_title_6": "VZW",
    "customer_title_7": "BVBA",
    "customer_title_8": "CVBA",
    "customer_title_9": "EV",
    "customer_title_10": ".",
    "customer_title_11": "Dhr. Mevr.",
    "customer_title_12": "Indép.",
    "customer_title_13": "EURL",
    "customer_title_14": "SAS",
    "customer_title_15": "VOF",
    "customer_title_16": "Comm.V./GCV",
    "customer_title_17": "Comm.V.A.",
    "First name": "Voornaam",
    "Last name": "Achternaam",
    "Company type": "Bedrijfstype",
    "Company name": "Bedrijfsnaam",
    "VAT": "BTW",
    "VAT number": "BTW-nummer",
    "Starts with an country code (usually 2 letters) followed by up to 13 characters, e.g. BE09999999XX, FRXX999999999, NL999999999B01": "Begint met een landcode (meestal 2 letters) gevolgd door maximaal 13 tekens, b.v. BE09999999XX, FRXX999999999, NL999999999B01",
    "Street address": "Adres",
    "Zip/Postal code": "Postcode",
    "City": "Plaats",
    "Country": "Land",
    "Free text": "Vrije tekst",

    // Tasks
    "Tasks": "Taken",
    "Task List": "Takenlijst",
    "No pending tasks found": "Geen openstaande taken gevonden",
    "All invoice requests have been processed": "Alle factuuraanvragen zijn verwerkt",

    // Dealers
    "Dealers": "Dealers",
    "Create Dealer Account": "Maak een dealeraccount aan",
    "Dealer created successfully.": "Dealer is succesvol aangemaakt.",
    "User ID": "Gebruikersnaam",
    "verified": "geverifieerd",
    "not verified": "niet geverifieerd",
    "E-mail is verified": "E-mail is geverifieerd",
    "Password": "Wachtwoord",
    "Dealer ID": "Dealer-ID",
    "Use 0 for a TotalEnergies dealer": "Gebruik 0 voor een TotalEnergies-dealer",
    "Create": "Creëren",

    // E-Mail
    "Send e-mail to customer": "Stuur een e-mail naar de klant",
    "E-Mail sent successfully.": "E-mail succesvol verzonden.",
    "Customer": "Klant",
    "E-mail type": "E-mailtype",
    "Invoice request finished": "Factuurverzoek voltooid",
    "Invoice request": "Factuur verzoek",
    "Invoice request ID": "Factuurverzoek-ID",
    "Language": "Taal",
    "Send": "Versturen",

    // Statistics
    "Statistics": "Statistieken",
    "Statistics Overview": "Statistieken overzicht",
    "Loading": "Bezig met laden",
    "Requests": "Verzoeken",
    "Monthly Invoices": "Maandelijkse facturen",
    "Success": "Succes",
    "Failure": "Mislukking",
    "Invoice Statistics": "Factuurstatistieken",
    "Daily Invoices": "Dagelijkse facturen",
    "1 Month": "1 maand",
    "6 Months": "6 maanden",
    "Current month": "Deze maand",
    "1 Year": "1 jaar",
    "Current year": "Huidige jaar",
    "All": "Allemaal",

    // Footer
    "Terms of service": "Algemene Gebruiksvoorwaarden",
    "Personal data & cookies": "Persoonlijke gegevens & cookies",
    "Sitemap": "Sitemap",
    "General conditions of sale": "Algemene verkoopvoorwaarden",
    "All our sites": "Al onze sites",
    "Follow us": "Volg ons",
    "Follow us on Facebook (new window)": "Volg ons op Facebook (nieuw venster)",
    "Follow us on Twitter (new window)": "Volg ons op Twitter (nieuw venster)",
    "Follow us on LinkedIn (new window)": "Volg ons op LinkedIn (nieuw venster)",
    "Follow us on YouTube (new window)": "Volg ons op YouTube (nieuw venster)",
    "Follow us on Instagram (new window)": "Volg ons op Instagram (nieuw venster)",
}
