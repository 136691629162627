export default {
    // Customers
    "customer_title_0": "Other",
    "customer_title_1": "Mr",
    "customer_title_2": "Mrs",
    "customer_title_3": "Miss",
    "customer_title_4": "Plc",
    "customer_title_5": "Ltd",
    "customer_title_6": "SE",
    "customer_title_7": "E.U.R.L.",
    "customer_title_8": "Plc",
    "customer_title_9": "SE",
    "customer_title_10": ".",
    "customer_title_11": "Mr and Mrs",
    "customer_title_12": "Indép.",
    "customer_title_13": "EURL",
    "customer_title_14": "SAS",
    "customer_title_15": "GP",
    "customer_title_16": "LP",
    "customer_title_17": "SCA",
}
