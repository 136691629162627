<script>
    import { Router, metatags } from '@sveltech/routify'
    import { routes } from '@sveltech/routify/tmp/routes'
    import * as translationData from './translations'
    import { t, addTranslationData, Authenticator } from 'totalinvoice-frontend-core'
    import "@fortawesome/fontawesome-free/js/all.js"

    // we need to queue our init till after Routify has been initialized
    setTimeout(() => window.routify.inBrowser)

    // add translation data to core module
    addTranslationData(translationData)

    // set metatags
    // TODO page needs to be reloaded if the language has changed
    metatags.title = $t('TotalEnergies Invoicing Admin Platform')
    metatags.description = $t('TotalEnergies Invoicing Admin Platform')

    let loginCheck = Authenticator.init()
</script>

<style type="text/scss" global>
    @import "./styles/global.scss";
    @import "./styles/app.scss";
</style>

{#await loginCheck}
    <span></span>
{:then loginStatus}
    <Router {routes} />
{/await}
